* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  display: flex;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
}

h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.vcf-splitter {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: Arial, sans-serif;
  height: 100%;
  padding: 20px;
}

.file-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.file-input input {
  padding: 10px;
  margin-right: 10px;
}

.file-input button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.link-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.link-item {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.link-item:hover a {
  text-decoration: underline;
}

.link-item a {
  text-decoration: none;
}

.zip-link {
  background-color: #2196f3;
  color: white;
}

.zip-link:hover {
  background-color: #058af7;
  color: #eee;
}

.zip-link a {
  color: white;
  text-decoration: none;
}

.zip-link:hover {
  background-color: #0b7dda;
}

.help-text {
  color: #999;
  font-size: 12px;
}

.loader-container {
  display: flex;
  justify-content: center;
}

.footer {
  margin-top: auto;
  font-size: 14px;
}

.footer a {
  text-decoration: none;
}